import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"

export const MessageSent = () => {
    return (
        <Layout>
        <SEO title={"Message Sent!"} description={"Message Confirmation Screen"}/>
            <h1>Message Sent!</h1>
    
            <p>Thank you for reaching out to me!</p>
            <p>Depending on my workload, I'll do my best to get back to you within a week!</p>
        </Layout>
    )
}

export default MessageSent;